import { SelectedChannel } from "../network/types"

export type StreamLinkComponent = {
    inCreateChannelName: string
    inCreateStreamerUserName: string
}

export const getStreamLink = (inCreateStreamUrl: string, toast: any) : StreamLinkComponent => {
    let inCreateChannelName
    let inCreateStreamerUserName
    if (inCreateStreamUrl.includes("youtube.com/watch?v=")) {
      inCreateChannelName = SelectedChannel.YOUTUBE
      const components = inCreateStreamUrl.split("youtube.com/watch?v=")
      if (components.length <= 1) {
        toast.error("Invalid Youtube link")
        return {} as StreamLinkComponent
      }

      inCreateStreamerUserName = components[components.length - 1]
    } else if (inCreateStreamUrl.includes("youtube.com/live/")) {
      inCreateChannelName = SelectedChannel.YOUTUBE

      const components = inCreateStreamUrl.split("youtube.com/live/")
      if (components.length <= 1) {
        console.log(components)
        toast.error("Invalid Youtube link")
        return {} as StreamLinkComponent
      }

      inCreateStreamerUserName = components[components.length - 1].split("?")[0]
    } else if (inCreateStreamUrl.includes("kick.com")) {
      inCreateChannelName = SelectedChannel.KICK

      const components = inCreateStreamUrl.split("kick.com/")
      if (components.length <= 1) {
        console.log(components)
        toast.error("Invalid Kick link")
        return {} as StreamLinkComponent
      }

      inCreateStreamerUserName = components[components.length - 1]
    } else if (inCreateStreamUrl.includes("twitch.tv")) {
      inCreateChannelName = SelectedChannel.TWITCH

      const components = inCreateStreamUrl.split("twitch.tv/")
      if (components.length <= 1) {
        console.log(components)
        toast.error("Invalid Twitch link")
        return {} as StreamLinkComponent
      }

      inCreateStreamerUserName = components[components.length - 1]
    } else if (inCreateStreamUrl.includes("streamed.su")) {
      inCreateChannelName = SelectedChannel.STREAMED

      const components = inCreateStreamUrl.split("streamed.su/watch/")
      if (components.length <= 1) {
        console.log(components)
        toast.error("Invalid Streamed link")
        return {} as StreamLinkComponent
      }

      const channelComponents = components[1].split("/")
      if (channelComponents.length !== 3) {
        console.log(channelComponents)
        toast.error("Invalid Streamed link. Only post live streams of streamed.")
        return {} as StreamLinkComponent
      }
      
      inCreateStreamerUserName = channelComponents[1] + '~' + channelComponents[0] + '~' + channelComponents[2]
    } else if (inCreateStreamUrl.includes("twitter.com") || inCreateStreamUrl.includes("x.com")) {
      inCreateChannelName = SelectedChannel.TWITTER

      const replacedInCreateStreamUrl = inCreateStreamUrl.replace("x.com/", "twitter.com/")
      const components = replacedInCreateStreamUrl.split("twitter.com/")
      if (components.length <= 1) {
        console.log(components)
        toast.error("Use the tweet that contains the live. URL must be of form: https://twitter.com/{user_name}/status/{tweet_id}")
        return {} as StreamLinkComponent
      }

      const twitterStatusLink = components[components.length - 1]
      const twitterComponents = twitterStatusLink.split("/status/")

      if (twitterComponents.length < 2) {
        console.log(twitterComponents)
        toast.error("Twitter link must be of form: " + `https://x.com/{user_name}/status/{tweet_id}`)
        return {} as StreamLinkComponent
      }

      inCreateStreamerUserName = twitterComponents[0] + "~" + twitterComponents[1]
    } else {
      toast.error("Only valid Youtube, Twitch, Kick and streamed.su links are supported")
      return {} as StreamLinkComponent
    }

    return {
        inCreateChannelName,
        inCreateStreamerUserName
    } as StreamLinkComponent
}

export const formatTimestamp = (timeLeft: number): string => {
  const secondLeft = timeLeft / 1000
  if (secondLeft > 0) {
    let seconds = Math.floor(secondLeft % 60)
    let minutes = Math.floor((secondLeft / 60) % 60)
    let hours = Math.floor((secondLeft / (60 * 60)) % 24)
    let days = Math.floor(secondLeft / (60 * 60 * 24))

    if (days > 0) {
      return days + " Days " + ('0' + hours).slice(-2) + ":" + 
        ('0' + minutes).slice(-2)  + ":" + ('0' + seconds).slice(-2)
    } else {
      return ('0' + hours).slice(-2) + ":" + ('0' + minutes).slice(-2)  + ":" + ('0' + seconds).slice(-2)
    }
  }
  
  return "starting soon..."
}