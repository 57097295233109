import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBK8bSpZoDkmPeDYb-9_lDgB8FDIw5nANA",
  authDomain: "coinbattle-68a18.firebaseapp.com",
  projectId: "coinbattle-68a18",
  storageBucket: "coinbattle-68a18.appspot.com",
  messagingSenderId: "66682165529",
  appId: "1:66682165529:web:19a765c089246789cc4d88",
  measurementId: "G-M3KFNT54RG"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app, messaging };
