import axios from "axios";

export const USER_TOKEN_KEY = 'USER_TOKEN_KEY'
export const REFERER_ID_KEY = 'REFERER_ID_KEY'

// const BASE_URL = 'http://localhost:8888/'
const BASE_URL = 'https://api.thelive.bet/'

export class HttpClient {

    static async get<T>(path: string): Promise<T> {
        const userToken = localStorage.getItem(USER_TOKEN_KEY)
        return axios.get(BASE_URL + path, {
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": "Bearer " + (userToken == null ? "" : userToken!),
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
          })
    }

    static async post<T>(path: string, data: any): Promise<T> {
        const userToken = localStorage.getItem(USER_TOKEN_KEY)
        return axios.post(BASE_URL + path, data, {
            headers: {
                "Cache-Control": "no-cache",
                "Authorization": "Bearer " + (userToken == null ? "" : userToken!),
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
          })
    }

}