

const IS_USER_LOGGED_IN_KEY = "IS_USER_LOGGED_IN";

export function markLogIn(loggedIn: boolean) {
    localStorage.setItem(IS_USER_LOGGED_IN_KEY, loggedIn ? 'true' : 'false')
}

export function isUserLoggedIn(): boolean {
    return localStorage.getItem(IS_USER_LOGGED_IN_KEY) === 'true'
}