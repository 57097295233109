import { useParams, useSearchParams } from "react-router-dom";
import "./streamView.css";
import { ZIM, ZIMEventOfReceiveConversationMessageResult, ZIMEventOfRoomStateChangedResult, ZIMMessage, ZIMRoomAdvancedConfig, ZIMRoomState } from "zego-zim-web";
import { useEffect, useRef, useState } from "react";
import { Bet, Stream } from "../model/types";
import { toast } from "react-toastify";
import { HttpClient } from "../network";
import { BetResponse, ChatMessage, CreateBetOrStreamResponse, MessageBody, MessageBodyType, PlaceBetResponse, Response, RoomResponse, SelectedChannel, UpdateRoomLivenessResponse, UpdateStreamResponse } from "../network/types";
import { isUserLoggedIn } from "../utils/login";
import { formatTimestamp, getStreamLink } from "../utils/stream";

export const getStreamType = (id: string | undefined): SelectedChannel => {
  if (id && id!.startsWith("kick~")) {
    return SelectedChannel.KICK
  } else if (id && id!.startsWith("youtube~")) {
    return SelectedChannel.YOUTUBE
  } else if (id && id!.startsWith("twitch~")) {
    return SelectedChannel.TWITCH
  } else if (id && id!.startsWith("streamed~")) {
    return SelectedChannel.STREAMED
  } else if (id && id!.startsWith("twitter~")) {
    return SelectedChannel.TWITTER
  } else if (id && id!.startsWith("featured~")) {
    return SelectedChannel.FEATURED
  }
  
  return SelectedChannel.UNKNOWN
}

export const canMarkStreamOffline = (id: string | undefined) => {
  const streamType = getStreamType(id)

  return streamType !== SelectedChannel.TWITCH && streamType !== SelectedChannel.KICK
}

export const getStreamId = (stream: Stream | undefined) => {
  return (stream?.newStreamId && stream?.newStreamId !== '') ? stream?.newStreamId : stream?.id
}

export const getStreamFrame = (id: string | undefined, isPreview: boolean, screenWidth: number, startTimestamp?: number) => {
  const streamType = getStreamType(id)
  if (streamType === SelectedChannel.KICK) {
    return <iframe className={isPreview ? "previewStreamPlayer": "streamPlayer"} src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=${isPreview ? 'false' : 'true'}`} allowFullScreen={!isPreview} /> 
  } else if (streamType === SelectedChannel.YOUTUBE) {
    return <iframe className={isPreview ? "previewStreamPlayer": "streamPlayer"} src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=${isPreview ? '0' : '1'}`} allow="autoplay" allowFullScreen={!isPreview} /> 
  } else if (streamType === SelectedChannel.TWITCH) {
    return <iframe className={isPreview ? "previewStreamPlayer": "streamPlayer"} src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen> </iframe>
  } else if (streamType === SelectedChannel.STREAMED) {
    return <iframe className={isPreview ? "previewStreamPlayer": "streamPlayer"} 
    sandbox="allow-same-origin allow-scripts"
    allow="encrypted-media; picture-in-picture;"
    allowFullScreen={true}
    src={`https://embedme.top/embed/${id!.split("~")[1]}/${id!.split("~")[2]}/${id!.split("~")[3]}`}></iframe>
  } else if (streamType === SelectedChannel.TWITTER) {
    return <div className={isPreview ? "previewStreamPlayer": "streamPlayer"} style={{
      marginBottom: isPreview ? "0.3rem" : (screenWidth <= 600 ? "0px" : "0px"),
      marginTop: isPreview ? (screenWidth <= 600 ? "0rem" : "-0.5rem") : "0px" }}>
      <blockquote data-align="center" data-media-max-width={isPreview ? (screenWidth <= 600 ? 0.9 * screenWidth : 0.3 * screenWidth) : screenWidth} className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light">
        <a href={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`}></a>
      </blockquote>
    </div>
  }
  
  return <div className="streamPlayer">Can't find this stream</div>
}

// comment

function StreamView(props: any) {
  let { id } = useParams();
  let zim: ZIM = props.zim
  let isIMLoggedIn = props.isIMLoggedIn
  let init = props.init
  let login = props.login
  let userCoins = props.userCoins
  let setUserCoins = props.setUserCoins
  let setUserPoints = props.setUserPoints
  let updateUserCoinBalance = props.updateUserCoinBalance
  let isResolver = props.isResolver
  let isCommunityMod = props.isCommunityMod
  let displayName = props.displayName
  let profilePicUrl = props.profilePicUrl
  let screenWidth = props.screenWidth
  let betCreationTakeRate = props.betCreationTakeRate
  let betCreationCost = props.betCreationCost
  let overallTakeRate = props.overallTakeRate
  let mixpanel = props.mixpanel
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCreatingBet, setIsCreatingBet] = useState<boolean>(false);
  const [isCreatingBetOnBackend, setIsCreatingBetOnBackend] = useState<boolean>(false);
  const [isPlacingBetOnBackend, setIsPlacingBetOnBackend] = useState<boolean>(false);
  const [inCreateBetDisplayName, setInCreateBetDisplayName] = useState<string>("");
  const [inCreateBetOptions, setInCreateBetOptions] = useState<string[]>(["", ""]);
  const [roomId, setRoomId] = useState<string>("");
  const [streamId, setStreamId] = useState<string>("");
  const [streamTitle, setStreamTitle] = useState<string | undefined>("");
  const roomIdRef = useRef<string>()
  roomIdRef.current = roomId
  const [bets, setBets] = useState<Bet[]>([]);
  const [trendingBet, setTrendingBet] = useState<Bet>();
  const [isStreamOver, setIsStreamOver] = useState<boolean>(true);
  const [streamStartTime, setStreamStartTime] = useState<number | undefined>();
  const [isShowingTrendingBet, setIsShowingTrendingBet] = useState<boolean>(true);
  const [selectedBetId, setSelectedBetId] = useState<string>("");
  const [selectedBetOptionIndex, setSelectedBetOptionIndex] = useState<number>(-1);
  const [selectedBetIdForSharing, setSelectedBetIdForSharing] = useState<string>("");
  const [selectedBetIdForMod, setSelectedBetIdForMod] = useState<string>("");
  const [selectedResolveOptionIndex, setSelectedResolveOptionIndex] = useState<number>(-1);
  const [isShowingSharePopup, setIsShowingSharePopup] = useState<boolean>(false);
  const [placedBetAmount, setPlacedBetAmount] = useState<number>(10);
  const [afterBetWinningCoinsAmount, setAfterBetWinningCoinsAmount] = useState<number>(0);
  const [countdownRemainingTime, setCountdownRemainingTime] = useState<number>(0);
  const [isMarkingTrendingBet, setIsMarkingTrendingBet] = useState<boolean>(false);
  const [isShowingMarkStreamLiveStatusPopup, setIsShowingMarkStreamLiveStatusPopup] = useState<boolean>(false);
  const [isShowingSwapStreamLinkPopup, setIsShowingSwapStreamLinkPopup] = useState<boolean>(false);
  const [isShowingReportStreamPopup, setIsShowingReportStreamPopup] = useState<boolean>(false);
  const [inCreateSwapStreamUrl, setInCreateSwapStreamUrl] = useState<string>("");
  const [inCreateReportStreamReason, setInCreateReportStreamReason] = useState<string>("");
  const [isResolvingBet, setIsResolvingBet] = useState<boolean>(false);
  const [chatBoxContent, setChatBoxContent] = useState("");
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [roomLiveUserCount, setRoomLiveUserCount] = useState<number>(0);
  const streamerRoomChatListRef = useRef<HTMLDivElement>(null);
  const popupContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    init()
    zim.on('receiveRoomMessage', (zim: any, message: ZIMEventOfReceiveConversationMessageResult) => {
      handleMessage(message.messageList)
    });
    zim.on('roomStateChanged', (zim: ZIM, roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
      handleRoomStateChange(roomStateChangedResult)
    })
    zim.on('error', (e) => { console.log("!!!!!", {e}) })

    fetchRoomInfo()
  }, [])

  useEffect(() => {
    if (isIMLoggedIn && roomId) {
      enterRoom()
    }
  }, [isIMLoggedIn, roomId]);

  useEffect(() => {
    const updateTimers = () => {
      const currentTime = Date.now();
      
      if (getStreamType(streamId) === SelectedChannel.FEATURED) {
        setCountdownRemainingTime(Math.max(streamStartTime! - currentTime, 0))
      }
    };

    // Initialize and update every second
    const interval = setInterval(() => {
      updateTimers();
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [streamId, streamStartTime]);

  useEffect(() => {
    if (bets.length > 0) {
      const queryBetId = searchParams.get('bid')

      if (queryBetId) {
        if (!trendingBet || trendingBet.id !== queryBetId) {
          const queryBet = bets.find((bet) => bet.id === queryBetId)
          if (queryBet) {
            setIsShowingTrendingBet(false)

            setTimeout(() => {
              const element = document.getElementById("betid~" + queryBetId)
    
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }, 50)
          }
        }

        setSearchParams("")
      }
    }
  }, [bets]);

  const enterRoom = () => {
    if (isIMLoggedIn) {
      zim.enterRoom({ roomID: roomId.substring(0, 32)!, roomName: roomId.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
        .then(() => {
          console.log("Logged into room " + roomId)

          setInterval(updateLiveUserCount, 3000 + Math.floor(Math.random() * 3000))
        })
        .catch((e) => {
          console.log("failed to log into room", {e})
        })
    } else {
      console.log("enterRoom in 1000ms because user isn't logged in")
      setTimeout(enterRoom, 1000)
    }
  }

  const updateLiveUserCount = () => {
    zim.queryRoomOnlineMemberCount(roomId.substring(0, 32)!)
    .then(function ({ roomID, count }) {
      setRoomLiveUserCount(count)
    })
    .catch(function (err) {
        console.error("failed to fetch live audience count: " + err.message)
    });
  }

  useEffect(() => {
    if (!isCreatingBet) {
      setInCreateBetDisplayName("")
      setInCreateBetOptions(["", ""])
    }
  }, [isCreatingBet]);

  useEffect(() => {
    updateAfterBetAmount(selectedBetOptionIndex, selectedBetId)
  }, [placedBetAmount]);

  const updateAfterBetAmount = (optionIndex: number, userSelectedBetId: string) => {
    if (optionIndex < 0) {
      return
    }

    const selectedBet = bets.find((bet) => bet.id === userSelectedBetId)
    if (!selectedBet) {
      return
    }

    if (isNaN(placedBetAmount)) {
      return
    }

    let betTVLSum = 0
    let otherOptionsBetTVLSum = 0
    for (let i = 0; i < selectedBet.optionTVL.length; i++) {
      betTVLSum += selectedBet.optionTVL[i]

      if (i !== optionIndex) {
        otherOptionsBetTVLSum += selectedBet.optionTVL[i]
      }
    }
    
    let price = (selectedBet.optionTVL[optionIndex] + placedBetAmount) / (betTVLSum + placedBetAmount)
    if (otherOptionsBetTVLSum === 0) {
      price = 1 / selectedBet.optionTVL.length
    }

    const shares = placedBetAmount / price
    
    setAfterBetWinningCoinsAmount(placedBetAmount + shares / (selectedBet.optionShares[optionIndex] + shares) *
      otherOptionsBetTVLSum * (1 - overallTakeRate))
  }

  useEffect(() => {
    if (popupContainerRef.current) {
      popupContainerRef.current.scrollTop = popupContainerRef.current.scrollHeight;
    }
  }, [inCreateBetOptions]);

  useEffect(() => {
    scrollChatToBottom()
  }, [chatMessages, isShowingTrendingBet]);

  const scrollChatToBottom = () => {
    if (chatMessages && streamerRoomChatListRef.current) {
      streamerRoomChatListRef.current.scrollTop = streamerRoomChatListRef.current.scrollHeight;
    }
  }

  const sendChatMessage = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    if (!chatBoxContent || chatBoxContent.trim() === '') {
      toast.error("Can't send empty chat!")
      return
    }

    let toConversationID = roomId.substring(0, 32)!; // Peer user's ID. 
    let conversationType = 1; // Conversation type, 1-on-1 chat: 0. In-room chat: 1. Group chat: 2. 
    let config = { 
        priority: 3, // Set message priority. Low: 1 (by default). Medium: 2. High: 3. 
    };

    let messageTextObj = { type: 1, message: JSON.stringify({
      text: chatBoxContent,
      messageType: MessageBodyType.CHAT_MESSAGE,
      userDisplayName: displayName,
      userPfpUrl: profilePicUrl
    } as MessageBody) };

    var notification = {
        onMessageAttached: function(message: any) {
          console.log("message sent notification", {message})
        }
    }

    zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
        .then(function ({ message }) {
          console.log("message sent", {message})
          setChatBoxContent("")
          handleMessage([message])
        })
        .catch(function (err) {
            console.log(err)
            if (err.code === 6000322) {
              zim.enterRoom({ roomID: roomId.substring(0, 32)!, roomName: roomId.substring(0, 32)! }, {} as ZIMRoomAdvancedConfig)
              .then(() => {
                console.log("Logged into room " + id)
                zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
                .then(function ({ message }) {
                  console.log("message sent", {message})
                  setChatBoxContent("")
                  handleMessage([message])
                })
                .catch((e) => {
                  console.log("failed to send message again", {e})
                  toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
                })
              })
              .catch((e) => {
                console.log("failed to log into room", {e})
                toast.error("Failed to send chat. Try again. (Reason: " + e.message + '"');
              })
            } else {
              toast.error("Failed to send chat. Try again.")
            }
        });
  }

  const handleRoomStateChange = (roomStateChangedResult: ZIMEventOfRoomStateChangedResult) => {
    if (roomStateChangedResult.state === 0 /** ZIMRoomState.Disconnected */) {
      console.log("handleRoomStateChange", {state: 'disconnected'})
      enterRoom()
    }
  }

  const handleMessage = (messageList: ZIMMessage[]) => {
    console.log("handleMessage", {messageList})

    for (const message of messageList) {
      if (!message || !message.message) {
        continue
      }
      
      if (!roomIdRef || !roomIdRef.current || (message.conversationID !== roomIdRef.current.substring(0, 32))) {
        console.log('skip syncing message from other conversations', {
          messageConversationId: message.conversationID,
          roomId: roomId
        })
        continue
      }

      const decodedMessageString = decodeURIComponent(message.message as string)

      console.log("decodedMessageString", { decodedMessageString })

      const messageBody = JSON.parse(decodedMessageString) as MessageBody
      console.log(messageBody)

      if (messageBody.messageType === MessageBodyType.RESOLVED_BET) {
        console.log(messageBody.betId)
        updateUserCoinBalance()
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.PLACED_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.ADD_BET) {
        console.log(messageBody.betId)
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            betId: messageBody.betId,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.CHAT_MESSAGE) {
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.userDisplayName + ": " + messageBody.text,
            userPfpUrl: messageBody.userPfpUrl,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.TRENDING_BET) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      } else if (messageBody.messageType === MessageBodyType.UPDATE_STREAM_LINK) {
        fetchRoomInfo()
        setChatMessages((prevChatMessages) => {
          const newMessages = [...prevChatMessages, {
            messageId: message.messageID,
            text: messageBody.text,
            type: messageBody.messageType
          } as ChatMessage]
          return newMessages
        })
      }
    }
  }

  const createBet = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setIsCreatingBet(true)
  }

  const onEnterPlaceBetSection = (betId: string, optionIndex: number) => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    selectBetOption(betId, optionIndex)
    updateAfterBetAmount(optionIndex, betId)
  }

  const selectBetOption = (betId: string, optionIndex: number) => {
    setSelectedBetId(betId)
    setSelectedBetOptionIndex(optionIndex)
    setPlacedBetAmount(10)
  }

  const onSharingBet = (betId: string) => {
    setSelectedBetIdForSharing(betId)
    setIsShowingSharePopup(true)
  }

  const onCloseSharingBet = () => {
    setSelectedBetIdForSharing("")
    setIsShowingSharePopup(false)
  }

  const placeBetOnBackend = async() => {
    if (placedBetAmount < 10) {
      toast.error("Please at least bet 10 coins")
      return
    }

    setIsPlacingBetOnBackend(true)
    const selectedBetIdCopy = selectedBetId.valueOf()
    HttpClient.post<Response<PlaceBetResponse>>('bet/make', {
      betId: selectedBetIdCopy,
      roomId: id,
      optionIndex: selectedBetOptionIndex,
      amount: placedBetAmount
    })
    .then((response) => {
      setUserCoins(response.data.userBalance)
      setUserPoints(response.data.userPoints)
      setBets((prevBets) => {
        const updatedBets: Bet[] = [...prevBets]
        for (let i = 0; i < updatedBets.length; i++) {
          if (updatedBets[i].id === selectedBetIdCopy) {
            updatedBets[i] = getBetFromBetResponse(response.data.bet)
          }
        }

        return updatedBets 
      })
      if (trendingBet?.id === response.data.bet.betId) {
        setTrendingBet(getBetFromBetResponse(response.data.bet))
      }
      selectBetOption("", -1)
      setIsPlacingBetOnBackend(false)

      mixpanel.track('USER_BETTED', {amount: placedBetAmount})
    })
    .catch((e): any => {
      console.log("!!!!failed to create bet", {e});
      toast.error(e.response?.data?.errorMessage ?? "Failed to place bet. Try again.");
      setIsPlacingBetOnBackend(false)
    })
  }

  const onInCreateBetOptionChanged = (newOptionName: string, index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions[index] = newOptionName
      return newOptions;
    })
  }

  const onCoinRemovedInCreationFlow = (index: number) => {
    setInCreateBetOptions((previousOptions) => {
      const newOptions = [...previousOptions];
      newOptions.splice(index, 1);
      return newOptions;
    })
  }

  const createBetOnBackend = async () => {
    if (!inCreateBetDisplayName || inCreateBetDisplayName.length === 0) {
      toast.error("Bet description can't be empty")
      return
    }

    let filteredBetOptions = []
    for (const betOption of inCreateBetOptions) {
      if (!betOption || betOption.trim().length === 0) {
        continue
      }

      filteredBetOptions.push(betOption.trim())
    }

    if (filteredBetOptions.length < 2) {
      toast.error("Need at least 2 non-empty outcome options")
      return
    }

    if (userCoins < betCreationCost) {
      toast.error("Need " + betCreationCost + " coin to create bet. Top up your account.")
      return
    }

    setIsCreatingBetOnBackend(true)
    HttpClient.post<Response<CreateBetOrStreamResponse>>('bet/create', {
      roomId: id,
      betContent: inCreateBetDisplayName,
      optionDisplayNames: filteredBetOptions
    })
    .then((response) => {
      const data = response.data
      
      fetchRoomInfo()
      setIsCreatingBet(false)
      setIsCreatingBetOnBackend(false)
      setUserCoins(data.userCoins)
      setUserPoints(data.userPoints)
    })
    .catch((e) => {
      console.log("!!!!failed to create bet", {e});
      toast.error(e.message);
      setIsCreatingBetOnBackend(false)
    })
  }

  const markBetAsTrendingOnBackend = () => {
    HttpClient.post<Response<RoomResponse>>('room/trending', {
      roomId: roomId,
      trendingBetId: selectedBetIdForMod
    })
    .then((response) => {
      const data = response.data
      
      fetchRoomInfo()
      hideMarkTrendingBetPopup()
      setIsShowingTrendingBet(true)
      toast.success("Trending bet updated");
    })
    .catch((e) => {
      console.log("!!!!failed to mark bet as trending", {e});
      toast.error(e.message);
    })
  }

  const resolveBetAsTrendingOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<RoomResponse>>('bet/resolve', {
      betId: betId,
      winningOptionIndex: selectedResolveOptionIndex
    })
    .then((response) => {
      fetchRoomInfo()
      toast.success("Bet resolved");
      hideResolveBetPopup()
    })
    .catch((e) => {
      console.log("!!!!failed to resolve bet", {e});
      toast.error(e.message);
    })
  }

  const markStreamLiveStatusOnBackend = () => {
    const betId = isShowingTrendingBet ? trendingBet!.id : selectedBetIdForMod
    HttpClient.post<Response<UpdateRoomLivenessResponse>>('room/markStreamOver', {
      roomId: roomId,
      isStreamOver: !isStreamOver
    })
    .then((response) => {
      fetchRoomInfo()
      toast.success("Stream marked as " + (response.data.room.isStreamOver ? "offline" : "live"));
      setIsShowingMarkStreamLiveStatusPopup(false)
    })
    .catch((e) => {
      console.log("!!!!failed to resolve bet", {e});
      toast.error(e.message);
    })
  }

  const calcOddsFromTVL = (betTVL: number, optionTVLs: number[]): string[] => {
    if (betTVL === 0) {
      const odds = Math.round(100 / optionTVLs.length) + "%"
      return optionTVLs.map((_tvl) => odds)
    }

    const odds: string[] = []
    for (const optionTVL of optionTVLs) {
      odds.push(Math.round(100 * optionTVL / betTVL) + "%")
    }
    return odds
  }

  const getBetFromBetResponse = (bet: BetResponse) => {
    const betTVL = bet.optionTVL.reduce((sum, current) => sum + current, 0)
    return {
      id: bet.betId,
      title: bet.betContent,
      tvl: betTVL.toFixed(0),
      tvlAmount: betTVL,
      options: bet.optionDisplayNames,
      resolvedOption: bet.resolvedOption,
      userBettedOptions: bet.userBettedOptions,
      odds: calcOddsFromTVL(betTVL, bet.optionTVL),
      optionTVL: bet.optionTVL,
      optionShares: bet.optionShares,
      takeRate: bet.takeRate
    } as Bet
  }

  const fetchRoomInfo = async () => {
    HttpClient.post<Response<RoomResponse>>('room', {roomId: id})
      .then((response) => {
        const data = response.data
        console.log("fetchRoomInfo", {data})
        
        setRoomId(data.roomId)
        setStreamId((data.newStreamId && data.newStreamId !== "") ? data.newStreamId : data.roomId)
        setStreamTitle(data.streamTitle)
        setBets(data.bets.map((bet) => getBetFromBetResponse(bet)))
        setTrendingBet(data.trendingBet ? getBetFromBetResponse(data.trendingBet) : undefined)
        setIsStreamOver(data.isStreamOver ?? false)
        setStreamStartTime(data.streamStartTime)
      })
      .catch((e) => {
        console.log("getBetFromBetResponse failed", e)
        toast.error("Failed to refresh. Try again (Reason: " + e.message + '"');
      })
  }

  const handleChatInputKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      sendChatMessage()
    }
  }

  const cancelPlacingBet = () => {
    setSelectedBetId("")
    setSelectedBetOptionIndex(-1)
  }

  const showResolveBetPopup = (betId: string) => {
    setIsResolvingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideResolveBetPopup = () => {
    setIsResolvingBet(false)
    setSelectedResolveOptionIndex(0)
    setSelectedBetIdForMod("")
  }

  const showMarkTrendingBetPopup = (betId: string) => {
    setIsMarkingTrendingBet(true)
    setSelectedBetIdForMod(betId)
  }

  const hideMarkTrendingBetPopup = () => {
    setIsMarkingTrendingBet(false)
    setSelectedBetIdForMod("")
  }

  const copyShareLinkAddress = async (link: string) => {
    await navigator.clipboard.writeText(link)
    toast.success("Link copied!")
  }

  const abortingCreatingStream = () => {
    setIsShowingSwapStreamLinkPopup(false)
    setInCreateSwapStreamUrl("")
  }

  const abortingReportStream = () => {
    setIsShowingReportStreamPopup(false)
    setInCreateReportStreamReason("")
  }

  const reportStreamLinkOnBackend = async() => {
    if (!isUserLoggedIn()) {
      login()
      return
    }
    
    if (!inCreateReportStreamReason || inCreateReportStreamReason === "") {
      toast.error("Report reason can't be empty")
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/report', {
      roomId: roomId,
      reason: inCreateReportStreamReason
    })
    .then((response) => {
      const data = response.data
      
      abortingReportStream()
      toast.success("Report sucessful! Thank you!")
    })
    .catch((e) => {
      console.log("!!!!report stream link", {e});
      toast.error("Failed to report stream. Try again. (Reason: " + e.message + '"');
    })
  }

  const swapStreamLinkOnBackend = async() => {
    if (!inCreateSwapStreamUrl || inCreateSwapStreamUrl.length === 0) {
      toast.error("Stream link can't be empty")
      return
    }

    const streamLink = getStreamLink(inCreateSwapStreamUrl, toast)
    console.error(streamLink)
    if (!streamLink.inCreateChannelName || !streamLink.inCreateStreamerUserName) {
      return
    }

    await HttpClient.post<Response<UpdateStreamResponse>>('room/update_stream_id', {
      streamerUserName: streamLink.inCreateStreamerUserName,
      channelName: streamLink.inCreateChannelName,
      roomId: roomId
    })
    .then((response) => {
      const data = response.data
      
      abortingCreatingStream()
      if (data.newStreamId) {
        setStreamId(data.newStreamId)
        toast.success("Swapped stream link!")
      } else {
        toast.error("Failed to swap link. Try again.")
      }
    })
    .catch((e) => {
      console.log("!!!!swap stream link", {e});
      toast.error("Failed to swap link. Try again. (Reason: " + e.message + '"');
    })
  }

  const getMessageIcon = (message: ChatMessage) => {
    switch (message.type) {
      case MessageBodyType.RESOLVED_BET:
        return <div className="chatMessagePfpImage">✅</div>
      case MessageBodyType.ADD_BET:
        return <div className="chatMessagePfpImage">➕</div>
      case MessageBodyType.PLACED_BET:
        return <div className="chatMessagePfpImage">💰</div>
      case MessageBodyType.TRENDING_BET:
        return <div className="chatMessagePfpImage">🔥</div>
        case MessageBodyType.UPDATE_STREAM_LINK:
          return <div className="chatMessagePfpImage">🔗</div>
      default:
        return <img className="chatMessagePfpImage" src={message.userPfpUrl}/>
    }
  }

  const getBetsUI = (betsToDisplay: Bet[], isTrendingBet: boolean) => {
    return betsToDisplay.map((bet, betIndex) => (
      <div id={"betid~" + bet.id} key={"betid~" + bet.id}>
        {
          (selectedBetId === bet.id) ? (
            <div className={betIndex === betsToDisplay.length - 1 ? "betItemContainer lastBetItemContainer" : "betItemContainer"}>
              <div className="selectedBetItemHeader">
                <div>{bet.options[selectedBetOptionIndex]}</div>
                <img onClick={cancelPlacingBet} className="placeBetCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="selectedBetInputContainer">
                <input type="number" className="selectedBetAmountInput" onKeyDown={(evt) => evt.key === '.' && evt.preventDefault()} value={placedBetAmount} onChange={e => setPlacedBetAmount(parseFloat(e.target.value))} placeholder="10"></input>
                <div className="buttonContainer">
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 1)}>+1</button>
                  <button className="btn selectedBetPlus" onClick={() => setPlacedBetAmount(placedBetAmount + 10)}>+10</button>
                </div>
              </div>
              <div className="afterBetWinTicketsContainer">
                <span>estimated to win </span>
                <img className="betBoxToWinCoinImage" src="/coin.png" />
                <span>{afterBetWinningCoinsAmount.toFixed(1)}</span>
              </div>
              <button disabled={isPlacingBetOnBackend} onClick={placeBetOnBackend} className="btn placeBetBtn">
                <div className="placeBetHintContainer">
                  {isPlacingBetOnBackend ? "Placing Bet...": "Place Bet"}
                </div>
              </button>
            </div>
          ) : (
            <div className={betIndex === betsToDisplay.length - 1 ? "betItemContainer lastBetItemContainer" : "betItemContainer"}>
              <div className="betTitleContainer">{bet.title}</div>
              {
                bet.options.map((option, optionIndex) => (
                  <>
                    <div className="betItemOptionsContainer">
                      <div className="betItemOptionName">{option}</div>
                      <div className="betItemOptionOdds">
                        {
                          (bet.resolvedOption >= 0) ? (
                            (bet.resolvedOption === optionIndex) ? '✅' : '❌'
                          ) : (
                            <>
                              <div className="betItemOptionOddsValue">{bet.odds[optionIndex]}</div>
                              <div className="betItemOptionOddsHint">Odds</div>
                            </>
                          )
                        }
                      </div>
                      {
                        bet.resolvedOption < 0 &&
                          (<button className="btn betBtn" onClick={() => onEnterPlaceBetSection(bet.id, optionIndex)}>Bet</button>)
                      }
                    </div>
                    {
                      bet.userBettedOptions[optionIndex] > 0 && (
                        <div className="userBettedAmount">(You betted {bet.userBettedOptions[optionIndex]} coins)</div>
                      )
                    }
                  </>
                ))
              }
              <div className="betItemFooter">
                <div className="betItemTVL">Total Wagered: <img className="betBoxCoinImage" src="/coin.png"/>{bet.tvl}</div>
                {
                  bet.resolvedOption < 0 ? (
                    <div className="betLivenessContainer">
                      <div className="betLivenessIcon"></div>
                      <div>Live</div>
                      <div className="shareBetContainer" onClick={() => onSharingBet(bet.id)}>
                        <img className="shareBetIcon" src="/share_button.png"></img>
                        <div>Share</div>
                      </div>
                    </div>
                  ) : (
                    <div className="betLivenessContainer">
                      <div>Resolved</div>
                    </div>
                  )
                }
              </div>
              {
                isTrendingBet && roomLiveUserCount && roomLiveUserCount > 0 && (
                  <div className="betItemFooter">
                    <div>Live Users: {roomLiveUserCount}</div>
                  </div>
                )
              }
              {
                !isResolver && !isCommunityMod && isTrendingBet && (
                  <div className="betModFooter">
                    <div className="betModFooterItem" onClick={() => setIsShowingReportStreamPopup(true)}>
                      <div className="betModFooterItemImage">🚨</div>
                      <div className="betModFooterItemText">Report</div>
                    </div>
                  </div>
                )
              }
              {
                isResolver && (
                  <div className="betModFooter">
                    {
                      bet.resolvedOption < 0 && (
                        <>
                          <div className="betModFooterItem" onClick={() => showResolveBetPopup(bet.id)}>
                            <div className="betModFooterItemImage">✅</div>
                            <div>Resolve</div>
                          </div>
                          {
                            !isShowingTrendingBet && (
                              <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                                <img className="betModFooterItemImage" src="/fire.png"></img>
                                <div>Trending</div>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                    {
                      isTrendingBet && canMarkStreamOffline(id) && (
                        <div className="betModFooterItem" onClick={() => setIsShowingMarkStreamLiveStatusPopup(true)}>
                          <div className="betModFooterItemImage">📺</div>
                          <div>{isStreamOver ? "Mark Live" : "Mark Offline"}</div>
                        </div>
                      )
                    }
                    {
                      isTrendingBet && (
                        <div className="betModFooterItem" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                          <div className="betModFooterItemImage">🔗</div>
                          <div>Swap</div>
                        </div>
                      )
                    }
                  </div>
                )
              }
              {
                isCommunityMod && (
                  <div className="betModFooter">
                    {
                      bet.resolvedOption < 0 && (
                        <>
                          {
                            !isShowingTrendingBet && (
                              <div className="betModFooterItem" onClick={() => showMarkTrendingBetPopup(bet.id)}>
                                <img className="betModFooterItemImage" src="/fire.png"></img>
                                <div>Trending</div>
                              </div>
                            )
                          }
                        </>
                      )
                    }
                    {
                      isTrendingBet && canMarkStreamOffline(id) && (
                        <div className="betModFooterItem" onClick={() => setIsShowingMarkStreamLiveStatusPopup(true)}>
                          <div className="betModFooterItemImage">📺</div>
                          <div>{isStreamOver ? "Mark Live" : "Mark Offline"}</div>
                        </div>
                      )
                    }
                    {
                      isTrendingBet && (
                        <div className="betModFooterItem" onClick={() => setIsShowingSwapStreamLinkPopup(true)}>
                          <div className="betModFooterItemImage">🔗</div>
                          <div>Swap</div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    ))
  }

  return (
    <div className="streamContainer">
      {
        isCreatingBet && (
          <>
            <div className="overlay" onClick={() => setIsCreatingBet(false)}></div>
            <div className="createBetContainer" ref={popupContainerRef}>
              <div className="popupHeader">
                  <img onClick={() => setIsCreatingBet(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="betDisplayNameContainer">
                <div>What is the bet?</div>
                <input value={inCreateBetDisplayName} onChange={e => setInCreateBetDisplayName(e.target.value)} className="betDisplayNameInput" placeholder="Can IShowSpeed dribble past Ibrahimović?"></input>
              </div>
              <div className="betOptionsContainer">
                <div>Outcome Options (need at least 2)</div>
                {
                  inCreateBetOptions.map((option, index) => (
                    <div className="betOptionItemContainer">
                      <input value={option} onChange={e => onInCreateBetOptionChanged(e.target.value, index)} className="optionNameInput" placeholder={index === 0 ? "Yes" : "No"}></input>
                      <img onClick={() => onCoinRemovedInCreationFlow(index)} className="removeOptionIcon" src="/removeIcon.png"></img>
                    </div>
                  ))
                }
                <div onClick={() => onInCreateBetOptionChanged("", inCreateBetOptions.length)} className="addMoreOptions">Add a new option</div>
              </div>
              <div className="createBetFooter">
                <button className="btn create-bet-btn" disabled={isCreatingBetOnBackend} onClick={createBetOnBackend}>{isCreatingBetOnBackend ? 'Creating...' : 'Create Bet'}</button>
                <div className="createBetFeeHint">* Costs {betCreationCost} coin. Earn {(betCreationTakeRate * 100).toFixed(1)}% fees of this bet (unless bet gets refunded)</div>
                <div className="lastCreateBetFeeHint">* Make highly specific questions to increase the likelihood of trending bets.</div>
              </div>
            </div>
          </>
        )
      }
      {
        isShowingSharePopup && (
          <>
            <div className="overlay" onClick={onCloseSharingBet}></div>
            <div className="sharePopupContainer" ref={popupContainerRef}>
              <div className="popupHeader">
                 <img onClick={onCloseSharingBet} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="sharePopupBody">
                <div className="twitterShare">
                  <img className="twitterShareIcon" src="/twitter_icon.png"></img>
                  <a className="twitterShareLink"
                    href={`https://twitter.com/intent/tweet?text="${bets.find((bet) => bet.id === selectedBetIdForSharing)?.title}"%0ACome watch and bet live with me on @thelivebet:%0A${window.location.href + '?bid=' + selectedBetIdForSharing}`}>
                      Share on X (Twitter)
                  </a>
                </div>
                <div className="shareUrlContainer">
                  <div className="shareUrlText">{window.location.href + '?bid=' + selectedBetIdForSharing}</div>
                  <div className="btn shareUrlContainerCopyButton" onClick={() => copyShareLinkAddress(window.location.href + '?bid=' + selectedBetIdForSharing)}>copy</div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        isMarkingTrendingBet && (
          <>
            <div className="overlay" onClick={hideMarkTrendingBetPopup}></div>
            <div className="markTrendingContainer" ref={popupContainerRef}>
              <div className="popupHeader">
                 <img onClick={hideMarkTrendingBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as trending?
              </div>
              <button className="btn create-bet-btn" onClick={markBetAsTrendingOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isResolvingBet && (
          <>
            <div className="overlay" onClick={hideResolveBetPopup}></div>
            <div className="resolveBetContainer" ref={popupContainerRef}>
              <div className="popupHeader">
                 <img onClick={hideResolveBetPopup} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                {
                  (isShowingTrendingBet ? trendingBet : bets.find((bet) => bet.id === selectedBetIdForMod))?.options.map((option, index) => (
                    <button className={selectedResolveOptionIndex === index ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                      onClick={() => setSelectedResolveOptionIndex(index)}>{option}</button>
                  ))
                }
                <button className={selectedResolveOptionIndex === 1000001 ? "selectedResolveOptionButton" : "unselectedResolveOptionButton"}
                  onClick={() => setSelectedResolveOptionIndex(1000001)}>Undecided</button>
              </div>
              <button className="btn create-bet-btn" onClick={resolveBetAsTrendingOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isShowingMarkStreamLiveStatusPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)}></div>
            <div className="markTrendingContainer" ref={popupContainerRef}>
              <div className="popupHeader">
                 <img onClick={() => setIsShowingMarkStreamLiveStatusPopup(false)} className="popupCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="confirmMarkTrendingText">
                Are you sure to mark this bet as {isStreamOver ? "live" : "offline"}?
              </div>
              <button className="btn create-bet-btn" onClick={markStreamLiveStatusOnBackend}>Confirm</button>
            </div>
          </>
        )
      }
      {
        isShowingSwapStreamLinkPopup && (
          <>
            <>
            <div className="overlay" onClick={abortingCreatingStream}/>
            <div className="createStreamContainer">
              <div className="createStreamHeader">
                <img onClick={abortingCreatingStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="streamUserNameContainer">
                <div>Swap Stream Link</div>
                <div className="streamPlatformHint">(supports Youtube, Twitch, Twitter, Kick and streamed.su streams)</div>
                <input value={inCreateSwapStreamUrl} onChange={e => setInCreateSwapStreamUrl(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="https://www.youtube.com/watch?v=Z8UTqxU3Cdo"></input>
              </div>
               <div className="createBetFooter">
               <button className="btn create-bet-btn" onClick={swapStreamLinkOnBackend}>Create Stream</button>
              </div>
            </div>
          </>s
          </>
        )
      }
      {
        isShowingReportStreamPopup && (
          <>
            <>
            <div className="overlay" onClick={abortingReportStream}/>
            <div className="createStreamContainer">
              <div className="createStreamHeader">
                <img onClick={abortingReportStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="streamUserNameContainer">
                <div>Report Stream</div>
                <input value={inCreateReportStreamReason} onChange={e => setInCreateReportStreamReason(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="Enter reason..."></input>
              </div>
               <div className="createBetFooter">
               <button className="btn create-bet-btn" onClick={reportStreamLinkOnBackend}>Report Stream</button>
              </div>
            </div>
          </>s
          </>
        )
      }
      {
        getStreamType(streamId) === SelectedChannel.FEATURED ? (
          <div className="streamPlayer">
            {
              (streamTitle && streamTitle !== "") && (
                <div className="countdownTextContainer">{streamTitle}</div>
              )
            }
            <div className="countdownTextContainer">
              {
                formatTimestamp(countdownRemainingTime)
              }
            </div>
          </div>
        ) : (
          getStreamFrame(streamId, false /*isPreview*/, screenWidth, streamStartTime)
        )
      }
      <div className="streamChat">
        <div className="streamTabContainer">
          <div className={isShowingTrendingBet ? "selectedStreamTabContainer" : "streamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(true)}>
            Trending Bet
          </div>
          <div className={!isShowingTrendingBet ? "selectedStreamTabContainer rightMostStreamTabContainerItem" :
            "streamTabContainerItem rightMostStreamTabContainerItem"}
            onClick={() => setIsShowingTrendingBet(false)}>
            All Bets
          </div>
        </div>
        {
          isShowingTrendingBet ? (
            <div className="trendingBetContainer">
              <div className="trendingBetsContainer">
                {
                  bets.length === 0 ? (
                    <div className="noBetsAndCreateContainer">
                      <div className="noBetsContainer">No bets yet.</div>
                      <button className="btn" onClick={createBet}>Create Bet</button>
                    </div>
                  ) : (
                    <div>
                      {
                        getBetsUI(trendingBet ? [trendingBet] : [], true /*isTrendingBet*/)
                      }
                    </div>
                  )
                }
              </div>
              <div className="streamerRoomChatList" ref={streamerRoomChatListRef}>
                  {
                    chatMessages.length === 0 ? (
                      <div className="noChatMessageHint">
                        No chat messages yet.
                      </div>
                    ) : (
                      <div>
                        {
                          chatMessages.map((message) => 
                            <div className="chatMessageItemContainer">
                              {
                                getMessageIcon(message)
                              }
                              {
                                message.type === MessageBodyType.ADD_BET ? (
                                  <div className="chatMessageDisplayText">
                                    {message.text}
                                  </div>
                                ) : (
                                  <div className="chatMessageDisplayText">{message.text}</div>
                                )
                              }
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>
                <div className="streamerRoomChatBox">
                  <textarea value={chatBoxContent} onKeyDown={handleChatInputKeyDown} onChange={e => setChatBoxContent(e.target.value)}
                    placeholder="Enter chat message..." className="streamerRoomChatInput"></textarea>
                  <img onClick={sendChatMessage} className="chatSendButton" src="/sendIcon-white.svg"></img>
                </div>
                <div className="createBet">
                  <button className="btn" onClick={createBet}>Create Bet</button>
                </div>
            </div>
          ) : (
            bets.length === 0 ? (
              <div className="noBetsAndCreateContainerFullPage">
                <div className="noBetsContainer">No bets yet.</div>
                <button className="btn" onClick={createBet}>Create Bet</button>
              </div>
            ): (
              <>
                <div className="betsContainer allBetsBetsContainer">
                  {
                    getBetsUI(bets, false /*isTrendingBet*/)
                  }
                </div>
                <div className="createBet">
                  <button className="btn" onClick={createBet}>Create Bet</button>
                </div>
              </>
            )
          )
        }
      </div>
    </div>
  );
}

export default StreamView;
