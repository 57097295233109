export type Response<T> = {
    data: T
}

export type LoginResponse = {
    zimToken: string,
    userId: string,
    coins: number,
    points: number,
    depositedUSDC: number,
    usdcDepositCap: number,
    address: string,
    refererId?: string,
    displayName?: string,
    pushToken?: string,
    profilePicUrl?: string,
    isResolver?: boolean,
    isCommunityMod: boolean,
    streamCreationCost: number,
    streamCreationTakeRate: number,
    betCreationCost: number,
    betCreationTakeRate: number,
    overallTakeRate: number
}

export type BuyCoinResponse = {
    message?: string,
    errorMessage?: string
}

export type RoomsReponse = {
    rooms: TopRoomResponse[],
    topRoom?: TopRoomResponse
}

export type TopUsersResponse = {
    topUsers: TopUserResponse[]
}

export type TopUserResponse = {
    userId: string,
    displayName?: string,
    coins: number,
    points: number
}

export type PresignUrlResponse = {
    upload_url: string,
}

export type BetResponse = {
    betId: string,
    betContent: string,
    optionDisplayNames: string[],
    optionTVL: number[],
    optionShares: number[],
    resolvedOption: number,
    createdAt: number,
    userBettedOptions: number[],
    takeRate: number
}

export type TopRoomResponse = {
    roomId: string,
    bettedTVL: number,
    newStreamId?: string,
    isStreamOver?: boolean,
    streamStartTime?: number,
    streamTitle?: string
}

export type UpdateRoomLivenessResponse = {
    room: RoomResponse
}

export type RoomResponse = {
    roomId: string,
    newStreamId?: string,
    streamTitle?: string,
    bets: BetResponse[],
    trendingBet: BetResponse,
    bettedTVL: number,
    isStreamOver?: boolean,
    streamStartTime?: number
}

export type CreateBetOrStreamResponse = {
    roomId: string,
    newStreamId?: string,
    bets: BetResponse[],
    trendingBet: BetResponse,
    userCoins: number,
    userPoints: number
}

export type UpdateStreamResponse = {
    newStreamId?: string,
}

export type PlaceBetResponse = {
    userBalance: number,
    userPoints: number,
    bet: BetResponse,
}

export type UpdateUserDisplayNameResponse = {
    displayName: string
}

export type IMMessage = {
    text: string,
    // 0 - text
    messageType: number,
}

export type MessageBody = {
    messageType: MessageBodyType,
    betId?: string,
    userDisplayName?: string,
    userPfpUrl?: string,
    text?: string,
    betterId?: string,
    amount?: number,
    optionIndex?: number
}

export enum MessageBodyType {
    ADD_BET = "ADD_BET",
    RESOLVED_BET = "RESOLVED_BET",
    PLACED_BET = "PLACED_BET",
    CHAT_MESSAGE = "CHAT_MESSAGE",
    TRENDING_BET = "TRENDING_BET",
    UPDATE_STREAM_LINK = "UPDATE_STREAM_LINK"
}

export type ChatMessage = {
    messageId: string,
    type: MessageBodyType,
    text: string,
    userDisplayName: string,
    userPfpUrl?: string,
    betId?: string,
}

export enum SelectedChannel {
    YOUTUBE = "youtube",
    KICK = "kick",
    TWITCH = "twitch",
    TWITTER = "twitter",
    STREAMED = "streamed",
    FEATURED = "featured",
    UNKNOWN = "unknown",
}